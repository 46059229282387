import { Injectable } from '@angular/core';

import { SentinelaDataTable } from '../data/sentinela';

@Injectable()
export class SentinelaService extends SentinelaDataTable {

    data = [
        {
            data: '1/1/2022',
            hora: '14:25:12',
            id: '002',
            cliente: 'João Augusto',
            acao: 'Realizou Operacação de Compara manualmente',
            visto: 'Resolvido',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: 'Realizou Operação de Venda manualmente',
            visto: 'Em aberto',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: 'Realizou novo aporte no valor de $USDT',
            visto: '',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: 'Realizou saque no valor de $USDT',
            visto: '',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: 'Botx efetuou operações contra tendência',
            visto: '',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: 'Botx efetuou operações contra tendência',
            visto: '',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: '...',
            visto: '',
            observacao: '',
        },
        {
            data: '',
            hora: '',
            id: '',
            cliente: '',
            acao: '...',
            visto: '',
            observacao: '',
        },
    ];

    getData() {
        return this.data;
    }
}
