import {Observable, of as observableOf} from 'rxjs';
import {Injectable} from '@angular/core';
import {Contacts, RecentUsers, UserData} from '../data/usuarios';

@Injectable()
export class UserService extends UserData {

  private time: Date = new Date;

  private users = {
    iury: { name: 'Iury Coelho', picture: '' },
  };
  private types = {
    moderado: 'moderado',
    conservador: 'conservador',
    arrojado: 'arrojado',
  };
  private contacts: Contacts[] = [
    { user: this.users.iury, type: this.types.arrojado },
  ];
  private recentUsers: RecentUsers[]  = [

    { user: this.users.iury, type: this.types.moderado, time: this.time.setHours(5, 29)},
  ];

  getUsers(): Observable<any> {
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return observableOf(this.recentUsers);
  }
}
