import { Injectable } from '@angular/core';
import { PainelControleTableData} from '../data/painel-controle';



@Injectable()
export class PainelControleService extends PainelControleTableData {

    data = [
        {
            bot: 'Bot1',
            perfil: 'Arrojado',
            alavancagem: '2x',
            capital: '10.00%',
            pnlNaoRealizado: '2.90%',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: 'Ligado',
        },
        {
            bot: 'Bot1',
            perfil: 'Moderado',
            alavancagem: '1x',
            capital: '15.00%',
            pnlNaoRealizado: '1.51%',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: 'Travado',
        },
        {
            bot: 'Bot1',
            perfil: 'Conservador',
            alavancagem: '1x',
            capital: '10.00%',
            pnlNaoRealizado: '0.00%',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: 'Desligado',
        },
        {
            bot: 'Bot2',
            perfil: 'Arrojado',
            alavancagem: '',
            capital: '',
            pnlNaoRealizado: '',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: '',
        },
        {
            bot: 'Bot2',
            perfil: 'Moderado',
            alavancagem: '',
            capital: '',
            pnlNaoRealizado: '',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: '',
        },
        {
            bot: 'Bot2',
            perfil: 'Conservador',
            alavancagem: '',
            capital: '',
            pnlNaoRealizado: '',
            pnlAcumD: '%',
            pnlAcumS: '%',
            pnlAcumM: '%',
            status: '',
        },
    ];

    getData() {
        return this.data;
    }
}

