import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Implements for <b><a href="#" target="_blank">Jartts</a></b> 2022
    </span>`,
})
export class FooterComponent {
}
