
import { Injectable } from '@angular/core';
import { CadastroClientesTableData } from '../data/cadastro-clientes';

@Injectable()
export class CadastroClientesService extends CadastroClientesTableData {

    data = [
        {
            id:   '2b64bfd6-d274-4a61-a2e1-df0894916154',
            name: ' Rubem Medeiros',
            email: 'rubem@hotmail.com',
            phone: '(83) 8813-4176',
            profile: 'Arrojado',
            paymentForm: 'HASH',
            status: 'Ativo',
            init: '13-04-2020 às 20:58:08',
        },

        {
            id:   '37c0b610-2e40-48e0-b45d-eaf701564a62',
            name: 'André Ramos',
            email: 'andreramos@hotmail.com',
            phone: '(83) 8840-5100',
            profile: 'Arrojado',
            paymentForm: 'HASH',
            status: 'Ativo',
            init: '10-04-2020 às 17:38:09',

        },

        {
            id:   '99735a5f-b5b4-473c-94a3-be3e1c983e18',
            name: 'Thaís Tomaz',
            email: 'thaisthomaz@hotmail.com',
            phone: '(83) 8845-7574',
            profile: 'Arrojado',
            paymentForm: 'HASH',
            status: 'Ativo',
            init: '09-04-2020 às 10:28:10',

        }
    ];

    getData() {
        return this.data;
    }
}


// import {Injectable, OnInit} from '@angular/core';
// import { CadastroClientesTableData } from '../data/cadastro-clientes';
// import {HttpBackend, HttpClient} from "@angular/common/http";
//
// interface Clientes {
//    perfil : String,
//    inicio : String,
//    telefone : String,
//    status : String,
//    nome : String,
//    id : String,
//    email : String,
//    formaPagamento : String
// }
//
// let arrayResult = []
//
// @Injectable()
// export class CadastroClientesService implements OnInit{
//
//   private customHttpClient: HttpClient;
//
//   clientes: Clientes[] = []
//
//   constructor(private http: HttpClient, backend: HttpBackend) {
//     this.customHttpClient = new HttpClient(backend);
//   }
//
//   ngOnInit(){
//      this.customHttpClient.get<Clientes[]>('https://nk7mw6mcvk.execute-api.ap-northeast-1.amazonaws.com/api/cliente/all')
//         .subscribe(
//             res => {
//               console.log(res);
//               this.clientes = res
//             },
//             (err)=> console.log(err));
//      arrayResult.push(this.clientes)
//
//      return arrayResult
//   }
//
//   getData() {
//       return this.clientes
//   }


  // data = [
  //   {
  //     id: 1,
  //     name: 'Iury',
  //     email: 'iury@gmail.com',
  //     phone: '(83)999557886',
  //     profile: 'Moderado',
  //     paymentForm: 'Boleto',
  //     status: 'Ativo',
  //     init: '12/02/2022',
  //   },
  //   {
  //     id: 2,
  //     name: 'Andre',
  //     email: 'andre@gmail.com',
  //     phone: '(83)999557006',
  //     profile: 'Conservador',
  //     paymentForm: 'Hash',
  //     status: 'Ativo',
  //     init: '10/02/2022',
  //   },
  //   {
  //     id: 3,
  //     name: 'Thais',
  //     email: 'thais@gmail.com',
  //     phone: '(83)999557000',
  //     profile: 'Arrojado',
  //     paymentForm: 'Hash',
  //     status: 'Inativo',
  //     init: '12/02/2022',
  //   },
  //   {
  //     id: 5,
  //     name: 'Claudio',
  //     email: 'claudio@gmail.com',
  //     phone: '(83)999557387',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Ativo',
  //     init: '03/01/2022',
  //   },
  //   {
  //     id: 6,
  //     name: 'Jader',
  //     email: 'jader@gmail.com',
  //     phone: '(83)988557311',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Ativo',
  //     init: '04/02/2022',
  //   },
  //   {
  //     id: 7,
  //     name: 'João',
  //     email: 'joaoa@gmail.com',
  //     phone: '(83)976556789',
  //     profile: 'Arrojado',
  //     paymentForm: 'Hash',
  //     status: 'Inativo',
  //     init: '03/01/2022',
  //   },
  //   {
  //     id: 9,
  //     name: 'Italo',
  //     email: 'italo@gmail.com',
  //     phone: '(83)888556743',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Inativo',
  //     init: '11/01/2022',
  //   },
  //   {
  //     id: 10,
  //     name: 'Arthur',
  //     email: 'arthur@gmail.com',
  //     phone: '(83)9888775632',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Inativo',
  //     init: '10/02/2022',
  //   },
  //   {
  //     id: 11,
  //     name: 'Joaqui',
  //     email: 'joaqui@gmail.com',
  //     phone: '(83)999567432',
  //     profile: 'Conservador',
  //     paymentForm: 'Hash',
  //     status: 'Ativo',
  //     init: '13/02/2022',
  //   },
  //   {
  //     id: 12,
  //     name: 'Sara',
  //     email: 'sara@gmail.com',
  //     phone: '(83)997667854',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Ativo',
  //     init: '03/02/2022',
  //   },
  //   {
  //     id: 13,
  //     name: 'Ana',
  //     email: 'ana@gmail.com',
  //     phone: '(83)999786543',
  //     profile: 'Conservador',
  //     paymentForm: 'Boleto',
  //     status: 'Inativo',
  //     init: '10/02/2022',
  //   },
  // ];

//}

