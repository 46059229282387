import { Injectable } from '@angular/core';
import { FinanceiroTableData } from '../data/financeiro';

@Injectable()
export class FinanceiroService extends FinanceiroTableData {

    data = [
        {
            id: '0001',
            usuario: 'João José',
            capitalInicial: 'USDT',
            aportes: 'USDT',
            retiradas: 'USDT',
            saldoFinal: 'USDT',
            lucroLiquido: 'USDT',
            performance: '%',
            comissao: 'USDT',
            pagamento: 'Hash',
            status: 'Ativo',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0002',
            usuario: 'João Augusto',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: 'Boleto',
            status: 'Suspenso',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0003',
            usuario: 'João Carlos',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0004',
            usuario: 'João Eduardo',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0005',
            usuario: 'João Miguel',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0006',
            usuario: 'João Luis',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0007',
            usuario: 'João Artur ',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0008',
            usuario: 'João Mateus ',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0009',
            usuario: 'João Pedro',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0010',
            usuario: 'Maria Helena ',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0011',
            usuario: 'Maria Clara',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0012',
            usuario: 'Maria Eduarda',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0014',
            usuario: 'Maria Isabel',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0015',
            usuario: 'Maria Joana',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0016',
            usuario: 'Maria Claudia',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',

        },
        {
            id: '0017',
            usuario: 'Maria Marta',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            saldoFinal: '',
            lucroLiquido: '',
            performance: '',
            comissao: '',
            pagamento: '',
            status: '',
            historico: '<a href="http://jartts.com.br/pages/area-admin/financeiro">Ver historico</a>',
        },
    ];

    getData() {
        return this.data;
    }
}
