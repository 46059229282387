import { Injectable } from '@angular/core';
import { PagamentosTableData } from '../data/pagamentos';

@Injectable()
export class PagamentosService extends PagamentosTableData {

    data = [
        {
            mes: 'Jan-22',
            capitalInicial: 'USDT',
            aportes: 'USDT',
            retiradas: 'USDT',
            performance: '%',
            lucroLiquido: 'USDT',
            comissao: 'USDT',
            boleto: '<a href="http://jartts.com.br/pages/area-cliente/pagamentos">link download</a>',
            status: 'Pago',
        },
        {
            mes: 'Dec-21',
            capitalInicial: '',
            aportes: '',
            retiradas: '',
            performance: '',
            lucroLiquido: '',
            comissao: '',
            boleto: '',
            status: 'Em aberto',
        },
    ];

    getData() {
        return this.data;
    }
}
